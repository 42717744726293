import Login from "./pages/Login";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Settings from "./pages/Settings";
import Layout from "./pages/Layout";
import RequireAuth from "./hooks/RequireAuth";
import NewPassword from "./pages/NewPassword";
import ForgotPassword from "./pages/ForgotPassword";
import ConfirmPass from "./pages/ConfirmPass";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Login />} />
        <Route path="/newPassword" element={<NewPassword />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/confirmPass" element={<ConfirmPass />} />
        <Route element={<RequireAuth allowedRoles={["3000"]} />}>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
