import React from "react";
import { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Stack, TextField, Box, Button, Alert, Container } from "@mui/material";
import { Typography } from "@mui/material";
import useAuth from "../hooks/useAuth";

const ConfirmPass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const codeRef = useRef();

  const { confirmPassword } = useAuth();
  const [error, setError] = useState(null);
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("Confirm new password...", location.state?.userName);
    if (newPassRef.current.value !== confirmPassRef.current.value) {
      setError("Passwords do not match");
      return;
    }

    try {
      confirmPassword(
        codeRef.current.value,
        newPassRef.current.value,
        location.state?.userName
      )
        .then((result) => {
          if (result === "SUCCESS") {
            console.log(" password reset successful");
            navigate("/");
          } else {
            setError(result);
            return;
          }
        })
        .catch((err) => {
          console.log(err.message);
          setError(err.message);
        });
    } catch (err) {
      console.log(err);
      setError(err);
      return;
    }
  };
  return (
    <Grid
      container
      spacing={0}
      display="flex"
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12}>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              px: 4,
              py: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
            }}
          >
            <Typography component="h1" variant="h5">
              Confirm New Password
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Stack direction="column" spacing={2} sx={{ width: "70%" }}>
              <Box
                component="form"
                onSubmit={onSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Confirmation code sent to your email"
                  name="username"
                  autoComplete="username"
                  inputRef={codeRef}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="New Password"
                  type="password"
                  inputRef={newPassRef}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Confirm New Password"
                  type="password"
                  inputRef={confirmPassRef}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Set New Password
                </Button>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ConfirmPass;
