import { CognitoUserPool } from "amazon-cognito-identity-js";

// This is the user pool for the slide scan app and Storage to be used is session storage
const poolData = {
  UserPoolId: "us-east-1_ClU1A3B2T",
  ClientId: "3jck6jsjjjn496aeknhl069qs",
  Storage: sessionStorage,
};

export default new CognitoUserPool(poolData);
