import React from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, TextField, Box, Button, Alert, Container } from "@mui/material";
import { Typography } from "@mui/material";
import useAuth from "../hooks/useAuth";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const usernameRef = useRef();

  const { forgotPassword } = useAuth();
  const [error, setError] = useState(null);
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("Forgot password...");
    try {
      forgotPassword(usernameRef.current.value)
        .then((result) => {
          if (result === "INPUT") {
            // console.log("Forgot password resolved");
            navigate("/confirmPass", {
              state: {
                userName: usernameRef.current.value,
              },
            });
          } else {
            setError(result);
            return;
          }
        })
        .catch((err) => {
          console.log(err.message);
          setError(err.message);
        });
    } catch (err) {
      console.log(err);
      setError(err);
      return;
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          py: 8,
          mt: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <Stack direction="column" spacing={2} sx={{ width: "70%" }}>
          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="UserName "
              name="username"
              autoComplete="username"
              autoFocus
              inputRef={usernameRef}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Reset Password
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
