import { Outlet } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Layout = () => {
  const theme = createTheme({
    palette: {
      background: {
        default: "lightgray",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Outlet />
    </ThemeProvider>
  );
};

export default Layout;
