import React, { useState, useRef } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { NewPassword } from "./NewPassword";

const Login = ({ callback }) => {
  const { authenticate, auth } = useAuth();
  // console.log("Login Auth", auth);
  const navigate = useNavigate();
  //   const location = useLocation();
  //   const from = location.state?.from?.pathname || "/";

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  const onSubmit = (e) => {
    e.preventDefault();

    console.log(
      "Login onSubmit",
      emailRef.current.value,
      passwordRef.current.value
    );
    setError("");
    setLoading(true);

    authenticate(emailRef.current.value, passwordRef.current.value)
      .then((data) => {
        // navigate(from, {replace: true});
        // see where there
        if (data === "authenticated") navigate("/home");
        else if (data === "newPasswordRequired")
          navigate("/newPassword", {
            state: {
              userName: emailRef.current.value,
              password: passwordRef.current.value,
            },
          });
        else setError("Invalid Credentials, Please try again later");
        // navigate('/home', {replace: true}')
      })
      .catch((err) => setError(err.message));
    setLoading(false);
  };

  return (
    /* set container max width to 400px on larger screens, on smaller screens width 100 % */
    <Grid
      container
      spacing={0}
      display="flex"
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12}>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              px: 4,
              py: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
            }}
          >
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="UserName "
                name="username"
                autoComplete="username"
                autoFocus
                inputRef={emailRef}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={passwordRef}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgotPassword" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                {/* <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
