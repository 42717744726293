import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React, { useState, useRef, useEffect } from "react";
import SideNav from "../components/SideNav";
import NavBar from "../components/NavBar";
import Box from "@mui/material/Box";
import { CardHeader, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import useAuth from "../hooks/useAuth";
import { Buffer } from "buffer";
import {
  APIGatewayClient,
  TestInvokeMethodCommand,
} from "@aws-sdk/client-api-gateway";
import { LambdaClient, InvokeCommand, LogType } from "@aws-sdk/client-lambda";
import useScanDetection from "use-scan-detection";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
} from "@aws-sdk/client-cognito-identity";
import TextField from "@mui/material/TextField";
function Home() {
  const { getSession } = useAuth();
  const [user, setUser] = useState(null);
  const [API, setAPI] = useState(null);
  const [image, setImage] = useState(null);
  const [response, setResponse] = useState(null);
  const [lambda, setLambda] = useState(null);

  const region = "us-east-1";
  const identityPoolId = "us-east-1:e6bbe6bb-701a-449d-9415-28e5ed93420f";
  const cognitoClient = new CognitoIdentityClient({ region });
  useEffect(() => {
    // const getSession = async () => {
    //     return await new Promise((resolve, reject) => {
    (async () => {
      const Obj = getSession()
        .then((sessionObj) => {
          if (sessionObj) {
            // console.log("sessionObj", sessionObj.user.username);
            setUser(sessionObj.user.username);
            const currentIdentity = cognitoClient
              .send(
                new GetIdCommand({
                  IdentityPoolId: identityPoolId,
                  Logins: {
                    "cognito-idp.us-east-1.amazonaws.com/us-east-1_ClU1A3B2T":
                      sessionObj.idToken.jwtToken,
                  },
                })
              )
              .then((data) => {
                // console.log("IdentityId", data.IdentityId);
                const credentials = cognitoClient
                  .send(
                    new GetCredentialsForIdentityCommand({
                      IdentityId: data.IdentityId,
                      Logins: {
                        "cognito-idp.us-east-1.amazonaws.com/us-east-1_ClU1A3B2T":
                          sessionObj.idToken.jwtToken,
                      },
                    })
                  )
                  .then((data) => {
                    console.log("Credentials", data);
                    // setIdentity(data.IdentityId);
                    setAPI(
                      new APIGatewayClient({
                        region: "us-east-1",
                        credentials: {
                          accessKeyId: data.Credentials.AccessKeyId,
                          secretAccessKey: data.Credentials.SecretKey,
                          sessionToken: data.Credentials.SessionToken,
                        },
                      })
                    );
                    setLambda(
                      new LambdaClient({
                        region: "us-east-1",
                        credentials: {
                          accessKeyId: data.Credentials.AccessKeyId,
                          secretAccessKey: data.Credentials.SecretKey,
                          sessionToken: data.Credentials.SessionToken,
                        },
                      })
                    );
                  })
                  .catch((err) => {
                    console.error(err, err.stack);
                    // setError(err);
                  });
              })
              .catch((err) => {
                console.error(err, err.stack);
                // setError(err);
              });
          }
        })
        .catch((err) => {
          console.log("getSession error", err);
          // setError(err);
        });
    })();
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  const apiCall = async (barcode) => {
    const regex = /Shift([A-Z]{3,5}-\d{6})/;

    const matches = barcode.match(regex);
    const case_no = matches ? matches[1] : null;
    console.log("case_no", case_no);
    const lambdaParams = {
      FunctionName: "imageRetrieverAPI-dev-api", // the lambda function we are going to invoke
      Payload: JSON.stringify({
        pathParameters: { caseNo: case_no },
      }), // pass params
      LogType: LogType.Tail,
    };
    try {
      const { Payload, LogResult } = await lambda.send(
        new InvokeCommand(lambdaParams)
      );
      const result = Buffer.from(Payload).toString(); // convert from base64
      const logs = Buffer.from(LogResult, "base64").toString(); // convert from base64
      // console.log("result", result);
      // console.log("logs", logs);
      // convert result to a JSON object
      const data = JSON.parse(result);
      console.log(data);
      if (data.body !== '{"message":"No case found"}') {
        setImage(data.body);
        const contentDisposition = data.headers["Content-Disposition"];
        // console.log("contentDisposition", contentDisposition);
        const regex = /filename=([A-Z]{3,5}-\d{6})/;

        const matches = contentDisposition.match(regex);
        const fileName = matches ? matches[1] : null;
        setResponse(fileName);
      } else setResponse(data.body);
    } catch (err) {
      console.log("err", err);
      // setResponse("No Barcode!");
    }
  };

  const [barcode, setBarcode] = useState(null);
  useScanDetection({
    onComplete: apiCall,
    minLength: 10,
  });

  const downloadFileName = useRef(null);
  const downloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    downloadFileName.current.value = "";
  };

  const base64ToBlob = (base64Data, contentType = "") => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const handleDownload = () => {
    if (downloadFileName.current.value === "") {
      alert("Please enter a file name!");
      return;
    }
    const contentType = "image/jpeg";
    const blob = base64ToBlob(image, contentType);
    downloadFile(blob, downloadFileName.current.value);
  };

  const StyledCard = styled(Card, {
    name: "StyledCard",
  })({
    background:
      "radial-gradient(circle, rgba(76,148,219,1) 30%, rgba(25,118,210,1) 75%)",
    color: "white",
    borderRadius: 3,
    padding: "0 30px",
    // "&:hover": {
    //   color: "white",
    //   borderRadius: 3,
    //   padding: "0 30px",
    //   transition: "all 0.5s ease",
    //   cursor: "pointer",
    //   transform: "scale(1.1)",
    //   boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    // },
  });

  return (
    <>
      <NavBar />
      <Box height={70} />
      <Box sx={{ display: "flex", minHeight: "calc(100vh - 64px)" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", "flex-direction": "column" }}
          >
            {/* <Grid item xs={6} sx={{ flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item>
                  <StyledCard sx={{ maxWidth: "100%", height: "10vh" }}>
                    <CardHeader
                      title="Open Case"
                      action={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <IconButton aria-label="play/pause">
                            <PlayArrowIcon
                              sx={{
                                color: "white",
                                height: 50,
                                width: 50,
                                borderStyle: " solid",
                                borderWidth: 2,
                                borderRadius: "50%",
                              }}
                            />
                          </IconButton>
                        </Box>
                      }
                      sx={{
                        maxWidth: "100%",
                      }}
                    ></CardHeader>
                  </StyledCard>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12} sx={{ height: "auto" }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <StyledCard sx={{ maxWidth: "100%", height: "85vh" }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="75vh"
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign={"center"}
                        >
                          {response && response}
                        </Typography>

                        <Box>
                          {image && (
                            <img
                              src={`data:image/jpeg;base64,${image}`}
                              alt="DRM-100001"
                              style={{ maxWidth: 400 }}
                            />
                          )}
                        </Box>
                      </CardContent>
                    </Box>
                  </StyledCard>
                </Grid>

                <Grid item xs={4}>
                  <Card sx={{ maxWidth: "100%", height: "85vh" }}>
                    {image && (
                      <CardContent>
                        <TextField
                          variant="outlined"
                          color="primary"
                          fullWidth
                          required
                          id="outlined-required"
                          label="FileName"
                          inputRef={downloadFileName}
                          focused
                        />
                        <IconButton
                          color="primary"
                          sx={{ p: "30px" }}
                          aria-label="directions"
                          onClick={() => handleDownload()}
                        >
                          <DownloadIcon /> Download
                        </IconButton>
                      </CardContent>
                    )}
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Home;
