import React from "react";
import { useContext, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../hooks/Account";

import { Stack, TextField, Box, Button, Alert, Container } from "@mui/material";
import { Typography } from "@mui/material";

const NewPassword = () => {
  const location = useLocation();
  console.log("User Name: ", location.state?.userName);

  console.log("Password: ", location.state?.password);

  const navigate = useNavigate();

  const passwordRef = useRef();
  const newPasswordRef = useRef();
  const { firstPassword } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("Setting First password...");

    if (passwordRef.current.value !== newPasswordRef.current.value) {
      setError("Passwords do not match");
      return;
    } else {
      try {
        firstPassword(
          location.state?.userName,
          location.state?.password,
          passwordRef.current.value
        )
          .then((result) => {
            if (result === "SUCCESS") {
              navigate("/home");
            } else {
              setError(result);
              return;
            }
          })
          .catch((err) => {
            console.log(err.message);
            setError(err.message);
          });
      } catch (err) {
        console.log(err);
        setError(err);
        return;
      }
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Set New Password
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <Stack direction="column" spacing={2} sx={{ width: "70%" }}>
          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              inputRef={passwordRef}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmNewPassword"
              label="Confirm New Password"
              type="password"
              inputRef={newPasswordRef}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change Password
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default NewPassword;
